import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { of, switchMap, tap } from 'rxjs';
import { AuthorizeService, IUser } from '../authorize.service';
import { ModalService } from '../modal.service';
import { AgencyNumberModalComponent } from '@msslib/components';
import { clientIds } from '@msslib/constants';
import { UserDetailsService } from '../user-details.service';

export const agencyCodeGuard: CanActivateFn =
  (
    _next: ActivatedRouteSnapshot,
  ) => {
    const authorizeService = inject(AuthorizeService);
    const modalService = inject(ModalService);
    const userDetailsService = inject(UserDetailsService);

    const showNotRegisteredModal = () => modalService.open({
      title: 'Not registered',
      message:
        'You have provided an agency number which is valid but not currently registered with Mortgage Club and ' +
        'therefore you cannot submit procuration fee claims.',
      showButtons: true,
      okLabel: 'Ok',
      hideCancel: true,
    }).then(() => null, () => null);

    return authorizeService.getUser().pipe(
      switchMap((user: IUser) => {
        if (user?.clientId === clientIds.simplyBiz) {
          return of(false);
        }

        if (user?.agencyNumber) {
          switch (authorizeService.isAgencyNumberRegistered) {
            // If this agency number is registered, then allow proc fee access
            case true:
              return of(true);

            // If this agency number is not registered, disallow proc fee access
            case false:
              showNotRegisteredModal();
              return of(false);

            // If we don't know yet whether this agency number is registered, then look it up and store the result. Then
            // pass the value back as the result of the guard (registered = allow, not registered = disallow).
            case null:
              return userDetailsService.isAgencyNumberRegistered(parseInt(user.agencyNumber))
                .pipe(tap(isRegistered => {
                  authorizeService.isAgencyNumberRegistered = isRegistered;
                  if (!isRegistered) {
                    showNotRegisteredModal();
                  }
                }));
          }
        }

        modalService
          .open({
            title: 'Please enter your Legal and General Agency Number',
            component: AgencyNumberModalComponent,
            size: 'md',
            sticky: true,
            client: _next.data.client,
          }).then(() => null, () => null);
        return of(false);
      }));
  };
